import { Route, Switch } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import WorkflowView from './WorkflowView'
import CommonHeader from './CommonHeader';


export default function App() {
  return (
    <div>
      <CommonHeader />
      <Box margin="xxxl" padding="xxxl">
        <Switch>
          <Route exact path="/" component={WorkflowView} />
          <Route path="/:buid?/:workflowName?/:effectiveDate?/:namespace?" component={WorkflowView} />
        </Switch>
      </Box>
    </div>
  );
}
