import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';

export default function CommonHeader() {
  return (
      <TopNavigation
        identity={{
          href: "/",
          title: "CBA Workflow Viewer",
        }}
        utilities={[
          {
            type: "menu-dropdown",
            text: "Learn more",
            ariaLabel: "about",
            title: "About",
            items: [
              {
                id: "about-org",
                text: "About Caseo",
                href: "https://w.amazon.com/bin/view/Caspian/Caseo/"
              },
              {
                id: "about-project",
                text: "Caspian Batch Aggregator",
                href: "https://evergreen.corp.amazon.com/caspian/batch/"
              }
            ]
          }
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu"
        }}
      />
  );
}